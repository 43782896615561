<template>
  <div>
    <el-alert title="操作提示" type="warning" description="请确认菜单修改正确后再点击保存，保存后刷新网页立刻生效" show-icon></el-alert>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>问题分类管理</span>
          <!-- <el-button class="button" type="text">菜单管理</el-button> -->
        </div>
      </template>
      <el-button type="primary" :icon="House" @click="addTopNode">添加顶级菜单</el-button>
      <el-tree
        class="permission-tree"
        style="margin-top: 10px"
        :data="menuArr"
        show-checkbox
        node-key="id"
        :highlight-current="true"
        :props="defaultProps"
        :expand-on-click-node="false"
        default-expand-all
      >
        <template #default="scope">
          <span class="custom-tree-node">
            <span>{{ scope.node.label }}</span>
            <span>
              <el-button
                v-if="scope.data.fatherId == 0"
                type="text"
                size="mini"
                :icon="FolderAdd"
                @click="append(scope.node, scope.data)"
              >添加子菜单</el-button>
              <el-button
                type="text"
                size="mini"
                :auto-insert-space="false"
                :icon="Edit"
                @click="edit(scope.node, scope.data)"
              >编辑</el-button>
              <el-button
                type="text"
                size="mini"
                :auto-insert-space="false"
                :icon="Delete"
                @click="remove(scope.node, scope.data)"
              >删除</el-button>
            </span>
          </span>
        </template>
      </el-tree>
    </el-card>
    <!-- 新增或者修改菜单 -->
    <el-dialog v-model="addorModify" :title="isAdd == true ? '新增菜单' : '修改菜单'" width="40%">
      <span v-if="currentMenu">>{{ currentMenu }}></span>
      <el-form
        ref="ruleForm"
        :model="ruleFormModel"
        :rules="menuRules"
        class="demo-ruleForm"
        style="margin-top: 20px;"
      >
        <el-form-item prop="menuName">
          <el-tooltip class="item" effect="dark" content="菜单名称" placement="left">
            <el-input v-model="ruleFormModel.menuName" placeholder="请输出菜单名称"></el-input>
          </el-tooltip>
        </el-form-item>

        <el-form-item prop="menuIcon">
          <el-tooltip class="item" effect="dark" content="菜单icon编码" placement="left">
            <el-input v-model="ruleFormModel.menuIcon" placeholder="请输出icon编码" prop="icon"></el-input>
          </el-tooltip>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addorModify = false">取消</el-button>
          <el-button type="primary" @click="confirm" :loading="confirming">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 删除菜单 -->
    <el-dialog v-model="dialogVisible" title="提示" width="30%">
      <span>确定删除该菜单？</span>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="delConfirm" :loading="confirming">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs, computed, ref } from "vue";
import { useStore } from "vuex";
import { House, Delete, FolderAdd, Edit } from "@element-plus/icons";
import { delMenu, newModifyMenu } from '../../service/request'
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "Manage-menu",
  setup() {
    const store = useStore();
    const dialogVisible = ref(false)
    const addorModify = ref(false)
    const confirming = ref(false)
    const ruleForm = ref(null)
    const state = reactive({
      delMenuId: '',
      isAdd: '',
      currentMenu: ''
    });
    //表单信息
    const ruleFormModel = reactive({
      id: '',//新增不需要填 修改填上对应id
      menuFatherId: '',
      menuName: '',
      menuIcon: ''
    });

    //表单验证
    const menuRules = {
      menuName: [
        {
          required: true,
          message: "Please input menuName",
          trigger: "blur",
        },
      ],
      menuIcon: [
        {
          required: true,
          message: "Please input menuIcon",
          trigger: "blur",
        },
      ],
    };
    const defaultProps = {
      children: "children",
      label: "menuName",
    };
    const addTopNode = () => {
      state.isAdd = true
      state.currentMenu = ''
      //新增全部清空
      ruleFormModel.menuFatherId = 0
      ruleFormModel.menuName = ''
      ruleFormModel.menuIcon = ''
      addorModify.value = true
    };
    const append = (node, data) => {
      console.log(node, data);
      state.isAdd = true
      state.currentMenu = data.menuName
      //新增全部清空
      ruleFormModel.menuFatherId = data.id
      ruleFormModel.menuName = ''
      ruleFormModel.menuIcon = ''
      addorModify.value = true
    }
    const edit = (node, data) => {
      console.log(data);
      state.isAdd = false
      state.currentMenu = data.menuName
      //修改
      ruleFormModel.menuFatherId = data.fatherId
      ruleFormModel.menuName = data.menuName
      ruleFormModel.menuIcon = data.menuIcon
      ruleFormModel.id = data.id
      addorModify.value = true
    }
    const remove = (node, data) => {
      dialogVisible.value = true
      state.delMenuId = data.id
    }
    const confirm = () => {
      ruleForm.value.validate((valid) => {
        if (valid) {
          console.log('chenggong');
          if (state.isAdd) {
            //新增
            let params = {
              menuName: ruleFormModel.menuName,
              menuFatherId: ruleFormModel.menuFatherId,
              menuIcon: ruleFormModel.menuIcon
            }
            newModifyMenu(params).then(res => {
              ElMessage({
                message: "新增成功",
                type: "success",
              });
              addorModify.value = false
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            }).catch(err => {
              ElMessage({
                message: "新增失败",
                type: "error",
              });
            })
          } else {
            //修改
            let params = {
              id: ruleFormModel.id,
              menuName: ruleFormModel.menuName,
              menuFatherId: ruleFormModel.menuFatherId,
              menuIcon: ruleFormModel.menuIcon
            }
            newModifyMenu(params).then(res => {
              ElMessage({
                message: "修改成功",
                type: "success",
              });
              addorModify.value = false
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            }).catch(err => {
              ElMessage({
                message: "修改失败",
                type: "error",
              });
            })
          }
        } else {
          ElMessage({
            message: "表单验证失败",
            type: "error",
          });
        }
      })

    }
    const delConfirm = () => {
      let params = {
        id: state.delMenuId
      }
      delMenu(params).then(res => {
        ElMessage({
          message: "删除成功",
          type: "success",
        });
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }).catch(res => {
        ElMessage({
          message: "删除失败",
          type: "error",
        });
      })
      dialogVisible.value = false

    }
    onMounted(() => { });
    return {
      House,
      Delete,
      FolderAdd,
      Edit,
      addTopNode,
      append,
      edit,
      remove,
      delConfirm,
      confirm,
      dialogVisible,
      addorModify,
      confirming,
      defaultProps,
      ...toRefs(state),
      ruleFormModel,
      menuRules,
      ruleForm,
      menuArr: computed(() => store.state.menuArr),
    };
  },
});
</script>

<style lang="less" scoped>
.custom-tree-node {
  span {
    font-size: 12px;
  }
  button {
    margin-left: 30px;
  }
}
.el-card {
  background-color: none !important;
  border: none !important;
  box-shadow: none !important;
}
</style>
